import React from "react";

import Layout from "../../layouts";
import SEO from "../../components/seo";

import FeedbackIMG from "../../images/svg/feedback.svg";
import feedback from "../../data/feedback.json";

function FeedbackPage() {
  return (
    <Layout>
      <SEO keywords={["my product hub"]} title="Feature-Feedbacks" />
      <section className="bg-mph-light-gray py-16">
        <div className="w-full flex flex-col items-center justify-center px-4 py-24 space-y-4">
          <div className="w-full max-w-6xl text-center space-y-4">
            <div className="font-bold text-3xl sm:text-6xl text-black tracking-wide space-y-2">
              <h2>Capture, Organize and Analyze</h2>
              <h2>Feedback in one place.</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="text-black body-font w-full">
        <div className="container px-5 py-12 mx-auto flex flex-col flex-wrap w-full">
          <div className="w-full mb-10 rounded-lg overflow-hidden -mt-40">
            <img
              alt="feature"
              className="object-cover object-center w-full shadow-lg"
              src={FeedbackIMG}
            />
          </div>
          <div className="flex flex-col flex-wrap justify-center items-center space-y-6 py-6">
            {feedback.headlines.map((item) => (
              <div
                className="flex flex-col w-2/3 bg-mph-light-gray rounded-lg p-4"
                key={item.title}
              >
                <div>
                  <h2 className="text-gray-900 text-3xl font-semibold mb-3">
                    {item.title}
                  </h2>
                  <p className="leading-relaxed text-xl">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default FeedbackPage;
